import { supabase } from '../lib/supabase';
import { useUserStore } from '../store/userStore';

export async function incrementViewCount(locationId: string): Promise<number> {
  try {
    const userState = useUserStore.getState();
    
    if (!userState.isAuthenticated) {
      console.warn('User not authenticated, view count not incremented');
      return 0;
    }

    // Call the RPC function to increment view count
    const { data, error } = await supabase.rpc('increment_view_count', {
      location_id: locationId
    });

    if (error) {
      console.error('Failed to increment view count:', error);
      return 0;
    }

    return data || 0;
  } catch (error) {
    console.error('Failed to increment view count:', error);
    return 0;
  }
}

export async function getViewCount(locationId: string): Promise<number> {
  try {
    const { data, error } = await supabase
      .from('locations')
      .select('view_count')
      .eq('id', locationId)
      .single();

    if (error) {
      console.error('Failed to get view count:', error);
      return 0;
    }

    return data?.view_count || 0;
  } catch (error) {
    console.error('Failed to get view count:', error);
    return 0;
  }
}