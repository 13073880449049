// 获取环境变量
const appId = import.meta.env.VITE_WECHAT_APP_ID;
const appSecret = import.meta.env.VITE_WECHAT_APP_SECRET;
const redirectUri = import.meta.env.VITE_WECHAT_REDIRECT_URI;

// 检查环境变量是否存在
if (!appId) {
  console.warn('警告: 未设置 VITE_WECHAT_APP_ID 环境变量');
}

if (!appSecret) {
  console.warn('警告: 未设置 VITE_WECHAT_APP_SECRET 环境变量');
}

if (!redirectUri) {
  console.warn('警告: 未设置 VITE_WECHAT_REDIRECT_URI 环境变量');
}

// 导出微信配置
export const WECHAT_CONFIG = {
  appId,
  appSecret,
  redirectUri,
  scope: 'snsapi_login',
  state: 'wechat_login'
};

// 记录配置信息（不包含敏感信息）
console.log('微信配置已加载:', { 
  appId, 
  redirectUri,
  scope: 'snsapi_login',
  hasSecret: !!appSecret
});