import React, { useState, useEffect } from 'react';
import { Button } from '../components/Button';
import { MessageSquare, MapPin, LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { WeChatLogin } from '../components/WeChatLogin';
import { useUserStore } from '../store/userStore';
import { SocialLinks } from '../components/SocialLinks';
import { SEO } from '../components/SEO';

export function Home() {
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const { isAuthenticated, id, nickname, clearUser } = useUserStore();
  
  useEffect(() => {
    // 如果用户已登录，检查是否是从其他页面返回的
    if (isAuthenticated && document.referrer.includes(window.location.host)) {
      console.log('用户已登录且从站内页面返回');
    }
  }, [isAuthenticated]);

  const handleWeChatLogin = () => {
    console.log('开始微信登录流程');
    setShowLogin(true);
  };

  const handleLoginSuccess = () => {
    console.log('登录成功');
    setShowLogin(false);
  };

  const handleLogout = () => {
    clearUser();
  };

  const handleExplore = () => {
    if (isAuthenticated) {
      navigate('/explore');
    } else {
      // 如果用户未登录，显示登录界面
      handleWeChatLogin();
    }
  };

  return (
    <>
      <SEO />
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex flex-col items-center justify-center p-4">
        {showLogin ? (
          <WeChatLogin onSuccess={handleLoginSuccess} />
        ) : (
          <div className="text-center">
            <h1 className="text-6xl font-bold text-gray-800 mb-20">奔县</h1>
            
            <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row justify-center">
              {!isAuthenticated ? (
                <Button onClick={handleWeChatLogin}>
                  <div className="flex items-center space-x-2">
                    <MessageSquare className="w-5 h-5" />
                    <span>微信登录</span>
                  </div>
                </Button>
              ) : (
                <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row">
                  <Button onClick={handleLogout}>
                    <div className="flex items-center space-x-2">
                      <LogOut className="w-5 h-5" />
                      <span>退出 {nickname ? `(${nickname})` : `(ID: ${id.slice(0, 8)})`}</span>
                    </div>
                  </Button>
                </div>
              )}
              
              <Button onClick={handleExplore}>
                <div className="flex items-center space-x-2">
                  <MapPin className="w-5 h-5" />
                  <span>探索县城</span>
                </div>
              </Button>
            </div>
          </div>
        )}
        
        <SocialLinks />
      </div>
    </>
  );
}