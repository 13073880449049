import axios from 'axios';
import { supabase } from '../lib/supabase';
import { WECHAT_CONFIG } from '../config/wechat';

interface WeChatAccessToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  openid: string;
  scope: string;
  unionid?: string;
}

interface WeChatUserInfo {
  openid: string;
  nickname: string;
  sex: number;
  province: string;
  city: string;
  country: string;
  headimgurl: string;
  privilege: string[];
  unionid?: string;
  access_token: string;
}

export async function getWeChatLoginUrl(): Promise<string> {
  const { appId, redirectUri, scope, state } = WECHAT_CONFIG;
  
  if (!appId || !redirectUri) {
    console.error('微信配置不完整，缺少appId或redirectUri');
    throw new Error('微信配置不完整');
  }

  // 确保redirectUri是完整的URL
  let fullRedirectUri = redirectUri;
  if (!fullRedirectUri.startsWith('http')) {
    // 如果不是完整URL，则构建完整URL
    const protocol = window.location.protocol;
    const host = window.location.host;
    fullRedirectUri = `${protocol}//${host}${redirectUri.startsWith('/') ? '' : '/'}${redirectUri}`;
  }
  
  console.log('完整回调URL:', fullRedirectUri);
  const encodedRedirectUri = encodeURIComponent(fullRedirectUri);
  
  // 检测是否在本地开发环境
  const isLocalDevelopment = window.location.hostname === 'localhost' || 
                            window.location.hostname.includes('127.0.0.1') ||
                            window.location.hostname.includes('stackblitz');
  
  if (isLocalDevelopment) {
    console.log('本地开发环境检测到，使用模拟登录流程');
    // 在本地开发环境中，创建一个模拟的微信登录URL，直接跳转到回调页面
    // 使用当前域名而不是硬编码的benxian.net
    const timestamp = Date.now();
    const randomCode = Math.random().toString(36).substring(2, 10);
    return `${window.location.origin}/wechat-callback?code=local_dev_${timestamp}_${randomCode}&state=${state}`;
  }
  
  // 生产环境使用真实的微信登录URL
  // 注意：这里使用的是网页授权的URL，确保获取用户信息
  const loginUrl = `https://open.weixin.qq.com/connect/qrconnect` +
    `?appid=${appId}` +
    `&redirect_uri=${encodedRedirectUri}` +
    `&response_type=code` +
    `&scope=${scope}` +
    `&state=${state}` +
    `#wechat_redirect`;
    
  console.log('生成的微信登录URL:', loginUrl);
  return loginUrl;
}

// 模拟微信API响应，用于开发和测试
function mockWeChatResponse(code: string): WeChatUserInfo {
  console.log('使用模拟数据进行微信登录测试，code:', code);
  
  // 生成一个基于code的唯一ID
  const openid = `wx_${code.substring(0, 8) || Math.random().toString(36).substring(2, 10)}`;
  
  return {
    openid,
    nickname: '曹鹏', // 使用真实姓名
    sex: 1,
    province: '北京',
    city: '朝阳区',
    country: '中国',
    headimgurl: 'https://thispersondoesnotexist.com/image',
    privilege: [],
    access_token: `mock_token_${Date.now()}`
  };
}

export async function handleWeChatCallback(code: string, state: string): Promise<WeChatUserInfo> {
  console.log('处理微信回调，参数:', { code, state, expectedState: WECHAT_CONFIG.state });
  
  // 检查是否为开发环境或测试环境
  const isDevelopment = window.location.hostname === 'localhost' || 
                        window.location.hostname.includes('stackblitz') ||
                        window.location.hostname.includes('127.0.0.1');
  
  // 如果是开发环境、配置不完整，或code以local_dev_开头，使用模拟数据
  if (isDevelopment || !WECHAT_CONFIG.appId || !WECHAT_CONFIG.appSecret || code.startsWith('local_dev_') || code.startsWith('test_')) {
    console.log('使用模拟数据进行登录');
    const mockUser = mockWeChatResponse(code);
    
    // 尝试在数据库中创建或更新用户
    try {
      await updateUserInDatabase(mockUser);
      console.log('模拟用户数据已保存到数据库');
    } catch (dbError) {
      console.warn('数据库操作失败，但将继续使用模拟数据:', dbError);
    }
    
    return mockUser;
  }

  try {
    // 生产环境调用真实微信API
    console.log('生产环境调用真实微信API');
    
    try {
      // 第二步：通过code获取access_token
      const tokenUrl = `https://api.weixin.qq.com/sns/oauth2/access_token` +
        `?appid=${WECHAT_CONFIG.appId}` +
        `&secret=${WECHAT_CONFIG.appSecret}` +
        `&code=${code}` +
        `&grant_type=authorization_code`;

      console.log('请求微信access_token...');
      const tokenResponse = await axios.get<WeChatAccessToken>(tokenUrl);
      const tokenData = tokenResponse.data;
      console.log('获取到access_token响应:', JSON.stringify(tokenData));

      if (!tokenData.access_token || !tokenData.openid) {
        console.error('获取access_token失败，响应数据:', tokenData);
        throw new Error('获取access_token失败');
      }

      // 第三步：通过access_token调用接口获取用户信息
      const userInfoUrl = `https://api.weixin.qq.com/sns/userinfo` +
        `?access_token=${tokenData.access_token}` +
        `&openid=${tokenData.openid}` +
        `&lang=zh_CN`;

      console.log('请求微信用户信息...');
      const userInfoResponse = await axios.get<WeChatUserInfo>(userInfoUrl);
      const userInfo = userInfoResponse.data;
      console.log('获取到用户信息响应:', JSON.stringify(userInfo));

      if (!userInfo.openid) {
        console.error('获取用户信息失败，响应数据:', userInfo);
        throw new Error('获取用户信息失败');
      }
      
      // 添加access_token到用户信息
      userInfo.access_token = tokenData.access_token;
      
      // 在数据库中创建或更新用户
      await updateUserInDatabase(userInfo);
      console.log('用户数据已保存到数据库');
      
      return userInfo;
    } catch (apiError) {
      console.error('微信API调用失败，详细错误:', apiError);
      
      // 如果是Axios错误，记录更多详细信息
      if (axios.isAxiosError(apiError)) {
        console.error('API响应状态:', apiError.response?.status);
        console.error('API响应数据:', apiError.response?.data);
      }
      
      throw apiError; // 重新抛出错误，不再使用模拟数据
    }
  } catch (error) {
    console.error('微信登录处理失败:', error);
    if (axios.isAxiosError(error)) {
      console.error('微信API错误详情:', error.response?.data);
      throw new Error(`微信登录失败: ${error.response?.data?.errmsg || error.message}`);
    }
    throw error;
  }
}

// 辅助函数：在数据库中创建或更新用户
async function updateUserInDatabase(userInfo: WeChatUserInfo): Promise<void> {
  try {
    console.log('准备更新数据库中的用户信息:', userInfo.openid);
    console.log('用户昵称:', userInfo.nickname);
    
    // 首先检查Supabase连接是否正常
    const { data: connectionTest, error: connectionError } = await supabase.from('users').select('count').limit(1);
    
    if (connectionError) {
      console.error('Supabase连接测试失败:', connectionError);
      throw new Error(`Supabase连接失败: ${connectionError.message}`);
    }
    
    console.log('Supabase连接正常，继续处理用户数据');
    
    // 检查用户是否存在
    const { data: existingUser, error: selectError } = await supabase
      .from('users')
      .select('*')
      .eq('wechat_id', userInfo.openid)
      .maybeSingle();

    // 准备用户数据 - 确保所有字段都正确映射
    const userData = {
      wechat_id: userInfo.openid,
      nickname: userInfo.nickname, // 确保正确保存昵称
      avatar: userInfo.headimgurl,
      province: userInfo.province,
      city: userInfo.city,
      last_login_at: new Date().toISOString()
    };

    console.log('查询结果:', { existingUser, selectError });
    console.log('准备保存的用户数据:', userData);

    if (!existingUser) {
      // 用户不存在，创建新用户
      console.log('用户不存在，创建新用户:', userData);
      
      const { data: insertData, error: insertError } = await supabase
        .from('users')
        .insert([userData])
        .select();

      if (insertError) {
        console.error('创建用户失败:', insertError);
        
        // 尝试获取更详细的错误信息
        if (insertError.details) {
          console.error('错误详情:', insertError.details);
        }
        if (insertError.hint) {
          console.error('错误提示:', insertError.hint);
        }
        
        throw new Error(`创建用户失败: ${insertError.message}`);
      }
      
      console.log('新用户创建成功:', insertData);
    } else {
      // 用户已存在，更新用户信息
      console.log('用户已存在，更新用户信息:', userData);
      
      const { data: updateData, error: updateError } = await supabase
        .from('users')
        .update(userData)
        .eq('wechat_id', userInfo.openid)
        .select();

      if (updateError) {
        console.error('更新用户失败:', updateError);
        
        // 尝试获取更详细的错误信息
        if (updateError.details) {
          console.error('错误详情:', updateError.details);
        }
        if (updateError.hint) {
          console.error('错误提示:', updateError.hint);
        }
        
        throw new Error(`更新用户失败: ${updateError.message}`);
      }
      
      console.log('用户信息更新成功:', updateData);
    }
  } catch (error) {
    console.error('数据库操作失败:', error);
    throw error;
  }
}