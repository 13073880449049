import React, { useEffect, useState } from 'react';
import { useUserStore } from '../store/userStore';
import { getWeChatLoginUrl } from '../services/wechatService';
import QRCode from 'qrcode.react';
import { MessageSquare, AlertCircle } from 'lucide-react';

interface WeChatLoginProps {
  onSuccess: () => void;
}

export function WeChatLogin({ onSuccess }: WeChatLoginProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [loginUrl, setLoginUrl] = useState('');
  const [useQrCode, setUseQrCode] = useState(false);
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      console.log('用户已认证，跳过登录流程');
      onSuccess();
      return;
    }

    const initializeLogin = async () => {
      try {
        setIsLoading(true);
        console.log('初始化微信登录...');
        const url = await getWeChatLoginUrl();
        console.log('获取到微信登录URL:', url);
        
        // 检测是否在本地开发环境
        const isLocalDevelopment = window.location.hostname === 'localhost' || 
                                  window.location.hostname.includes('127.0.0.1') ||
                                  window.location.hostname.includes('stackblitz');
        
        // 检测是否在微信浏览器中
        const isWechatBrowser = /MicroMessenger/i.test(navigator.userAgent);
        
        if (isLocalDevelopment) {
          // 本地开发环境直接跳转到模拟回调
          console.log('本地开发环境，直接跳转到模拟回调');
          window.location.href = url;
        } else if (isWechatBrowser) {
          // 在微信浏览器中直接跳转
          window.location.href = url;
        } else {
          // 在其他浏览器中，先显示二维码
          setLoginUrl(url);
          setUseQrCode(true);
          setIsLoading(false);
        }
      } catch (err) {
        console.error('微信登录初始化失败:', err);
        setError('登录初始化失败，请稍后重试');
        setIsLoading(false);
      }
    };

    initializeLogin();
  }, [isAuthenticated, onSuccess]);

  const handleDirectLogin = () => {
    if (loginUrl) {
      window.location.href = loginUrl;
    }
  };

  if (error) {
    return (
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="flex justify-center mb-4">
          <AlertCircle className="w-12 h-12 text-red-500" />
        </div>
        <h2 className="text-xl font-bold text-gray-800 mb-4">登录失败</h2>
        <p className="text-red-500 mb-4">{error}</p>
        <div className="space-y-4">
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            重试
          </button>
          
          <div>
            <button
              onClick={() => {
                // 使用模拟登录（仅用于开发环境）
                const mockUrl = `${window.location.origin}/wechat-callback?code=manual_test_${Date.now()}&state=wechat_login`;
                window.location.href = mockUrl;
              }}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors text-sm"
            >
              使用模拟登录（开发测试）
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4 text-gray-600">正在准备微信登录...</p>
        <p className="mt-2 text-sm text-gray-500">请稍候，正在连接微信服务</p>
      </div>
    );
  }

  if (useQrCode && loginUrl) {
    return (
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h2 className="text-xl font-bold text-gray-800 mb-4">微信扫码登录</h2>
        <p className="text-gray-600 mb-4">请使用微信扫描下方二维码进行登录</p>
        <div className="mb-6 flex justify-center">
          <div className="p-2 border-2 border-green-500 rounded-lg">
            <QRCode value={loginUrl} size={200} />
          </div>
        </div>
        <p className="text-gray-600 mb-6">
          <span className="text-green-600 font-medium">提示：</span>
          登录后即可浏览全部县城内容
        </p>
        <div className="space-y-4">
          <button
            onClick={handleDirectLogin}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors flex items-center mx-auto"
          >
            <MessageSquare className="w-5 h-5 mr-2" />
            <span>直接跳转到微信登录</span>
          </button>
          
          {/* 仅在开发环境显示模拟登录按钮 */}
          {(window.location.hostname === 'localhost' || 
            window.location.hostname.includes('127.0.0.1') ||
            window.location.hostname.includes('stackblitz')) && (
            <button
              onClick={() => {
                const mockUrl = `${window.location.origin}/wechat-callback?code=manual_test_${Date.now()}&state=wechat_login`;
                window.location.href = mockUrl;
              }}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors text-sm flex items-center mx-auto"
            >
              <span>使用模拟登录（开发测试）</span>
            </button>
          )}
        </div>
      </div>
    );
  }

  return null;
}