import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { LocationCard } from '../components/LocationCard';
import { CategoryList } from '../components/CategoryList';
import { Location } from '../types/location';
import { useUserStore } from '../store/userStore';
import { SEO } from '../components/SEO';

const mockLocations: Location[] = [
  {
    id: 'huayuan',
    fullName: '湖南花垣',
    shortName: '花垣',
    province: {
      id: 'hunan',
      fullName: '湖南省',
      shortName: '湖南'
    },
    region: 'central',
    category: {
      id: '02',
      name: '江河'
    },
    imageUrl: 'https://images.unsplash.com/photo-1501785888041-af3ef285b470?auto=format&fit=crop&q=80',
    description: '湘西腹地的自然奇观，层叠梯田与民族文化交相辉映',
    rating: 4.8,
    viewCount: 15678
  },
  // ... 其他位置数据
];

export function Categories() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3;
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);

  // 检查用户认证状态
  useEffect(() => {
    console.log('分类页面加载，用户认证状态:', isAuthenticated ? '已登录' : '未登录');
  }, [isAuthenticated]);

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <SEO 
        title="县城分类 - 奔县"
        description="按类别浏览中国特色县城"
      />
      <header className="bg-white shadow-sm">
        <div className="max-w-5xl mx-auto px-4 py-4">
          <div className="flex items-center">
            <button 
              onClick={() => navigate('/explore')}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <ArrowLeft className="w-6 h-6 text-gray-600" />
            </button>
            <h1 className="ml-4 text-2xl font-bold text-gray-800">奔县</h1>
          </div>
        </div>
      </header>

      <main className="max-w-5xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {mockLocations.map((location) => (
            <LocationCard key={location.id} location={location} />
          ))}
        </div>
      </main>

      <CategoryList 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}