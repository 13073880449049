import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User, UserState } from '../types/user';

const initialState: Omit<UserState, 'setUser' | 'clearUser'> = {
  id: '',
  wechatId: '',
  nickname: '',
  avatar: '',
  city: '',
  province: '',
  createdAt: '',
  lastLoginAt: '',
  token: '',
  isAuthenticated: false,
};

export const useUserStore = create<UserState>()(
  persist(
    (set, get) => ({
      ...initialState,
      setUser: (user: User) => {
        console.log('设置用户状态:', user);
        set({
          id: user.id,
          wechatId: user.wechatId,
          nickname: user.nickname,
          avatar: user.avatar,
          city: user.city,
          province: user.province,
          createdAt: user.createdAt,
          lastLoginAt: new Date().toISOString(),
          token: user.token,
          isAuthenticated: true,
        });
        
        // 确认状态已设置
        const state = get();
        console.log('用户状态已更新:', state.isAuthenticated);
      },
      clearUser: () => {
        console.log('清除用户状态');
        set(initialState);
      },
    }),
    {
      name: 'user-storage',
      partialize: (state) => ({
        id: state.id,
        wechatId: state.wechatId,
        nickname: state.nickname,
        avatar: state.avatar,
        city: state.city,
        province: state.province,
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        createdAt: state.createdAt,
        lastLoginAt: state.lastLoginAt,
      }),
      // 确保状态持久化
      storage: {
        getItem: (name) => {
          const value = localStorage.getItem(name);
          console.log('从存储中获取用户状态:', name, value ? '存在' : '不存在');
          return value ? JSON.parse(value) : null;
        },
        setItem: (name, value) => {
          console.log('保存用户状态到存储:', name);
          localStorage.setItem(name, JSON.stringify(value));
        },
        removeItem: (name) => {
          console.log('从存储中移除用户状态:', name);
          localStorage.removeItem(name);
        },
      },
    }
  )
);