import React from 'react';
import { Github, Mail } from 'lucide-react';

export function SocialLinks() {
  return (
    <div className="fixed bottom-8 left-0 right-0 z-50">
      <div className="max-w-xl mx-auto px-4 flex justify-center space-x-8">
        <a
          href="https://github.com/your-username/county-explorer"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-gray-600 hover:text-gray-900 transition-colors"
        >
          <Github className="w-5 h-5 mr-2" />
          <span>GitHub</span>
        </a>

        <a
          href="mailto:your-email@example.com"
          className="flex items-center text-gray-600 hover:text-gray-900 transition-colors"
        >
          <Mail className="w-5 h-5 mr-2" />
          <span>联系我们</span>
        </a>
      </div>
    </div>
  );
}