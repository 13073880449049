import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { Home } from './pages/Home';
import { Explore } from './pages/Explore';
import { Categories } from './pages/Categories';
import { WeChatCallback } from './pages/WeChatCallback';
import { useUserStore } from './store/userStore';
import { Admin } from './pages/Admin';
import { Debug } from './pages/Debug';

// 路由守卫组件，用于检查用户是否已登录
interface ProtectedRouteProps {
  children: React.ReactNode;
  redirectTo?: string;
  requireAuth?: boolean;
}

function ProtectedRoute({ children, redirectTo = '/', requireAuth = true }: ProtectedRouteProps) {
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);
  
  // 如果需要认证但用户未登录，重定向到首页
  if (requireAuth && !isAuthenticated) {
    console.log('用户未登录，重定向到首页');
    return <Navigate to={redirectTo} replace />;
  }
  
  return <>{children}</>;
}

// 创建路由配置
const createAppRouter = () => {
  return createBrowserRouter([
    {
      path: '/',
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/explore',
      element: (
        <ProtectedRoute requireAuth={true}>
          <Explore />
        </ProtectedRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/categories',
      element: (
        <ProtectedRoute requireAuth={true}>
          <Categories />
        </ProtectedRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/wechat-callback',
      element: <WeChatCallback />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/admin',
      element: (
        <ProtectedRoute requireAuth={true}>
          <Admin />
        </ProtectedRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/debug',
      element: <Debug />,
      errorElement: <ErrorPage />,
    }
  ]);
};

function ErrorPage() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center p-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">页面出现错误</h1>
        <p className="text-gray-600 mb-4">抱歉，页面加载出现问题。请刷新页面重试。</p>
        <div className="flex justify-center">
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            刷新页面
          </button>
        </div>
        <div className="mt-4">
          <button
            onClick={() => window.location.href = '/'}
            className="px-4 py-2 text-blue-500 hover:underline"
          >
            返回首页
          </button>
        </div>
      </div>
    </div>
  );
}

export function App() {
  // 确保用户状态在应用启动时被正确加载
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);
  
  useEffect(() => {
    // 记录用户认证状态，帮助调试
    console.log('应用启动，用户认证状态:', isAuthenticated ? '已登录' : '未登录');
    
    // 检查当前URL是否为微信回调
    const isWeChatCallback = window.location.pathname.includes('wechat-callback');
    if (isWeChatCallback) {
      console.log('检测到微信回调URL:', window.location.href);
    }
    
    // 添加全局错误处理
    window.addEventListener('error', (event) => {
      console.error('全局错误:', event.error);
    });
    
    // 添加未捕获的Promise错误处理
    window.addEventListener('unhandledrejection', (event) => {
      console.error('未处理的Promise拒绝:', event.reason);
    });
  }, [isAuthenticated]);
  
  // 创建路由器
  const router = createAppRouter();
  
  return <RouterProvider router={router} />;
}