import React, { useEffect, useState } from 'react';
import { ArrowLeft, RefreshCw, Database, User, UserCheck } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { WECHAT_CONFIG } from '../config/wechat';
import { supabase } from '../lib/supabase';
import { useUserStore } from '../store/userStore';

export function Debug() {
  const navigate = useNavigate();
  const [envVars, setEnvVars] = useState<Record<string, string>>({});
  const [dbStatus, setDbStatus] = useState<'loading' | 'connected' | 'error'>('loading');
  const [dbError, setDbError] = useState<string | null>(null);
  const [userCount, setUserCount] = useState<number | null>(null);
  const [isTestingDb, setIsTestingDb] = useState(false);
  const [userState, setUserState] = useState<any>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  
  const currentUser = useUserStore();
  
  useEffect(() => {
    // 收集所有以 VITE_ 开头的环境变量
    const vars: Record<string, string> = {};
    Object.keys(import.meta.env).forEach(key => {
      if (key.startsWith('VITE_')) {
        vars[key] = import.meta.env[key] as string;
      }
    });
    
    // 添加微信配置
    vars['WECHAT_CONFIG'] = JSON.stringify({
      appId: WECHAT_CONFIG.appId,
      redirectUri: WECHAT_CONFIG.redirectUri,
      scope: WECHAT_CONFIG.scope,
      state: WECHAT_CONFIG.state,
      hasSecret: !!WECHAT_CONFIG.appSecret
    }, null, 2);
    
    // 添加当前URL信息
    vars['CURRENT_URL'] = window.location.href;
    vars['HOSTNAME'] = window.location.hostname;
    vars['ORIGIN'] = window.location.origin;
    
    setEnvVars(vars);
    
    // 获取当前用户状态
    setUserState(currentUser);
    
    // 测试数据库连接
    testDatabaseConnection();
    
    // 加载用户列表
    loadUsers();
  }, [currentUser]);
  
  const testDatabaseConnection = async () => {
    setIsTestingDb(true);
    setDbStatus('loading');
    setDbError(null);
    
    try {
      // 测试数据库连接
      const { data, error, status } = await supabase.from('users').select('count').limit(1);
      
      if (error) {
        console.error('数据库连接测试失败:', error);
        setDbStatus('error');
        setDbError(`连接错误 (${status}): ${error.message}`);
        return;
      }
      
      console.log('数据库连接测试成功:', data);
      setDbStatus('connected');
      
      // 获取用户数量
      const { count, error: countError } = await supabase
        .from('users')
        .select('*', { count: 'exact', head: true });
      
      if (countError) {
        console.error('获取用户数量失败:', countError);
      } else {
        setUserCount(count);
      }
    } catch (err) {
      console.error('数据库测试异常:', err);
      setDbStatus('error');
      setDbError(err instanceof Error ? err.message : '未知错误');
    } finally {
      setIsTestingDb(false);
    }
  };
  
  const loadUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .order('last_login_at', { ascending: false })
        .limit(10);
      
      if (error) {
        console.error('加载用户列表失败:', error);
        return;
      }
      
      setUsers(data || []);
    } catch (err) {
      console.error('加载用户列表异常:', err);
    } finally {
      setIsLoadingUsers(false);
    }
  };
  
  const createTestUser = async () => {
    try {
      const testId = `test_${Date.now()}`;
      const { data, error } = await supabase
        .from('users')
        .insert([{
          wechat_id: testId,
          nickname: `曹鹏`, // 使用真实姓名
          avatar: 'https://thispersondoesnotexist.com/image',
          province: '测试省',
          city: '测试市',
          created_at: new Date().toISOString(),
          last_login_at: new Date().toISOString()
        }])
        .select();
      
      if (error) {
        alert(`创建测试用户失败: ${error.message}`);
        console.error('创建测试用户失败:', error);
      } else {
        alert(`测试用户创建成功: ${data[0].wechat_id}`);
        console.log('测试用户创建成功:', data);
        testDatabaseConnection(); // 刷新用户数量
        loadUsers(); // 刷新用户列表
      }
    } catch (err) {
      alert(`创建测试用户异常: ${err instanceof Error ? err.message : '未知错误'}`);
      console.error('创建测试用户异常:', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex items-center">
            <button 
              onClick={() => navigate('/')}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <ArrowLeft className="w-6 h-6 text-gray-600" />
            </button>
            <h1 className="ml-4 text-2xl font-bold text-gray-800">系统调试</h1>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* 数据库状态 */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
            <div>
              <h2 className="text-lg font-medium text-gray-900">数据库状态</h2>
              <p className="mt-1 text-sm text-gray-500">Supabase 连接状态和用户数据</p>
            </div>
            <button 
              onClick={testDatabaseConnection}
              disabled={isTestingDb}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
            >
              <RefreshCw className={`w-4 h-4 mr-2 ${isTestingDb ? 'animate-spin' : ''}`} />
              刷新
            </button>
          </div>
          <div className="border-t border-gray-200 px-4 py-5">
            <div className="flex items-center mb-4">
              <Database className="w-5 h-5 mr-2 text-gray-500" />
              <span className="font-medium">连接状态:</span>
              {dbStatus === 'loading' && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  检查中...
                </span>
              )}
              {dbStatus === 'connected' && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  已连接
                </span>
              )}
              {dbStatus === 'error' && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                  连接失败
                </span>
              )}
            </div>
            
            {dbError && (
              <div className="mb-4 p-3 bg-red-50 text-red-700 text-sm rounded-md">
                {dbError}
              </div>
            )}
            
            {dbStatus === 'connected' && (
              <div className="flex items-center mb-4">
                <User className="w-5 h-5 mr-2 text-gray-500" />
                <span className="font-medium">用户数量:</span>
                <span className="ml-2">{userCount !== null ? userCount : '加载中...'}</span>
              </div>
            )}
            
            <div className="mt-4 flex space-x-4">
              <button
                onClick={createTestUser}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
              >
                创建测试用户
              </button>
              <button
                onClick={loadUsers}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                刷新用户列表
              </button>
            </div>
          </div>
        </div>
        
        {/* 用户列表 */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
            <div>
              <h2 className="text-lg font-medium text-gray-900">用户列表</h2>
              <p className="mt-1 text-sm text-gray-500">最近登录的用户</p>
            </div>
            <div className="flex items-center">
              {isLoadingUsers && (
                <RefreshCw className="w-5 h-5 text-blue-500 animate-spin mr-2" />
              )}
              <span className="text-sm text-gray-500">
                {users.length} 个用户
              </span>
            </div>
          </div>
          <div className="border-t border-gray-200">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ID
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      用户信息
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      地区
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      最后登录
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.wechat_id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {user.avatar ? (
                            <img 
                              src={user.avatar} 
                              alt={user.nickname || '用户头像'} 
                              className="h-10 w-10 rounded-full"
                            />
                          ) : (
                            <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                              <UserCheck className="h-6 w-6 text-gray-500" />
                            </div>
                          )}
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {user.nickname || '未设置昵称'}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {user.province || '-'} {user.city || '-'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(user.last_login_at).toLocaleString('zh-CN')}
                      </td>
                    </tr>
                  ))}
                  {users.length === 0 && (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 text-center text-sm text-gray-500">
                        {isLoadingUsers ? '加载中...' : '暂无用户数据'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        {/* 当前用户状态 */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-lg font-medium text-gray-900">当前用户状态</h2>
            <p className="mt-1 text-sm text-gray-500">本地存储的用户信息</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5">
            <pre className="bg-gray-50 p-3 rounded overflow-auto text-sm">
              {JSON.stringify(userState, null, 2)}
            </pre>
            
            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => {
                  useUserStore.getState().clearUser();
                  setUserState(useUserStore.getState());
                }}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              >
                清除用户状态
              </button>
            </div>
          </div>
        </div>
        
        {/* 环境变量 */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-lg font-medium text-gray-900">环境变量和配置信息</h2>
            <p className="mt-1 text-sm text-gray-500">用于调试微信登录和其他配置问题</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              {Object.keys(envVars).map((key) => (
                <div key={key} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">{key}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {key === 'WECHAT_CONFIG' ? (
                      <pre className="bg-gray-50 p-2 rounded overflow-auto">{envVars[key]}</pre>
                    ) : (
                      envVars[key]
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        
        {/* 微信登录测试 */}
        <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-lg font-medium text-gray-900">微信登录测试</h2>
            <p className="mt-1 text-sm text-gray-500">测试微信登录流程</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5">
            <button
              onClick={() => {
                const url = `${window.location.origin}/wechat-callback?code=test_code_${Date.now()}&state=wechat_login`;
                window.open(url, '_blank');
              }}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              测试回调URL
            </button>
            <p className="mt-2 text-sm text-gray-500">
              点击上面的按钮将打开一个模拟的微信回调URL，用于测试回调处理逻辑
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}