import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useUserStore } from '../store/userStore';
import { handleWeChatCallback } from '../services/wechatService';
import { SEO } from '../components/SEO';
import { ArrowLeft, AlertCircle, CheckCircle, RefreshCw } from 'lucide-react';

export function WeChatCallback() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const setUser = useUserStore((state) => state.setUser);
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [missingParams, setMissingParams] = useState(false);
  const [processingComplete, setProcessingComplete] = useState(false);
  const [debugInfo, setDebugInfo] = useState<any>({});
  const [userInfo, setUserInfo] = useState<any>(null);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        setIsLoading(true);
        
        // 记录当前URL和参数，用于调试
        const currentUrl = window.location.href;
        console.log('微信回调页面加载，当前URL:', currentUrl);
        
        // 从URL中获取code和state参数
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        const currentDebugInfo = { 
          pathname: location.pathname,
          search: location.search,
          fullUrl: currentUrl,
          code: code || 'missing', 
          state: state || 'missing',
          timestamp: new Date().toISOString(),
          userAgent: navigator.userAgent,
          retryCount
        };
        
        setDebugInfo(currentDebugInfo);
        console.log('微信回调参数:', currentDebugInfo);

        // 如果用户已经登录，直接跳转到探索页面
        if (isAuthenticated) {
          console.log('用户已登录，直接跳转到探索页面');
          navigate('/explore', { replace: true });
          return;
        }

        // 如果缺少必要参数，显示错误信息但不跳转
        if (!code) {
          console.warn('缺少必要的参数 (code)');
          setMissingParams(true);
          setIsLoading(false);
          return;
        }

        console.log('开始处理微信回调，code:', code);
        
        // 处理微信回调
        const userInfoData = await handleWeChatCallback(code, state || 'wechat_login');
        console.log('获取到用户信息:', userInfoData);
        setUserInfo(userInfoData);
        
        // 更新本地用户状态 - 确保所有字段都正确映射
        setUser({
          id: userInfoData.openid,
          wechatId: userInfoData.openid,
          nickname: userInfoData.nickname || '游客', // 确保正确保存昵称
          avatar: userInfoData.headimgurl || '',
          city: userInfoData.city || '',
          province: userInfoData.province || '',
          createdAt: new Date().toISOString(),
          lastLoginAt: new Date().toISOString(),
          token: userInfoData.access_token
        });

        console.log('用户状态已更新，准备跳转到探索页面');
        setProcessingComplete(true);
        
        // 延迟一下再跳转，确保状态已保存
        setTimeout(() => {
          // 登录成功后重定向到探索页面
          navigate('/explore', { replace: true });
        }, 1000);
      } catch (err) {
        console.error('微信登录失败:', err);
        setError(err instanceof Error ? err.message : '登录失败，请重试');
      } finally {
        setIsLoading(false);
      }
    };

    handleCallback();
  }, [searchParams, setUser, navigate, location, isAuthenticated, retryCount]);

  const handleRetry = () => {
    setIsLoading(true);
    setError('');
    setRetryCount(prev => prev + 1);
  };

  // 如果缺少参数，显示友好的提示页面
  if (missingParams) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <SEO title="微信登录 - 参数错误" description="微信登录回调页面参数错误" />
        <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full mx-4">
          <div className="flex justify-center mb-4">
            <AlertCircle className="w-16 h-16 text-yellow-500" />
          </div>
          <h1 className="text-xl font-bold text-gray-800 mb-4">无效的回调请求</h1>
          <p className="text-gray-600 mb-6">
            此页面需要从微信登录页面跳转而来，无法直接访问。
          </p>
          <div className="flex flex-col space-y-4">
            <div className="text-xs text-gray-500 bg-gray-100 p-3 rounded text-left overflow-auto">
              <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
            </div>
            <button
              onClick={() => navigate('/', { replace: true })}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center justify-center"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              返回首页
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <SEO title="微信登录 - 登录失败" description="微信登录失败" />
        <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full mx-4">
          <div className="flex justify-center mb-4">
            <AlertCircle className="w-16 h-16 text-red-500" />
          </div>
          <h1 className="text-xl font-bold text-gray-800 mb-4">登录失败</h1>
          <p className="text-red-500 mb-4">{error}</p>
          <div className="text-xs text-gray-500 bg-gray-100 p-3 rounded text-left overflow-auto mb-4">
            <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
          </div>
          <div className="flex flex-col space-y-3">
            <button
              onClick={handleRetry}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center justify-center"
            >
              <RefreshCw className="w-4 h-4 mr-2" />
              重试登录
            </button>
            <button
              onClick={() => navigate('/', { replace: true })}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors flex items-center justify-center"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              返回首页
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <SEO title="微信登录 - 处理中" description="正在处理微信登录" />
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">正在处理登录...</p>
          <p className="mt-2 text-sm text-gray-500">
            处理参数: {location.search}
          </p>
        </div>
      </div>
    );
  }

  if (processingComplete) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <SEO title="微信登录 - 登录成功" description="微信登录成功" />
        <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full mx-4">
          <div className="flex justify-center mb-4">
            <CheckCircle className="w-16 h-16 text-green-500" />
          </div>
          <h1 className="text-xl font-bold text-gray-800 mb-2">登录成功</h1>
          
          {userInfo && (
            <div className="flex items-center justify-center mb-4">
              {userInfo.headimgurl && (
                <img 
                  src={userInfo.headimgurl} 
                  alt={userInfo.nickname || '用户头像'} 
                  className="w-12 h-12 rounded-full border-2 border-green-200"
                />
              )}
              <div className="ml-3 text-left">
                <p className="font-medium text-gray-800">{userInfo.nickname || '游客'}</p>
                <p className="text-sm text-gray-500">
                  {userInfo.province} {userInfo.city}
                </p>
              </div>
            </div>
          )}
          
          <p className="text-gray-600 mb-6">您已成功登录，正在跳转到探索页面...</p>
          
          <button
            onClick={() => navigate('/explore', { replace: true })}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            立即前往探索页面
          </button>
        </div>
      </div>
    );
  }

  // 默认加载状态
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <SEO title="微信登录 - 处理中" description="正在处理微信登录" />
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4 text-gray-600">正在初始化登录处理...</p>
      </div>
    </div>
  );
}