import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { LocationCard } from '../components/LocationCard';
import { ExploreFilters } from '../components/ExploreFilters';
import { locations, regions, provinces, categories } from '../data/locations';
import { Location } from '../types/location';
import { SEO } from '../components/SEO';
import { useUserStore } from '../store/userStore';

export function Explore() {
  const navigate = useNavigate();
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [minRating, setMinRating] = useState(0);
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);
  const nickname = useUserStore((state) => state.nickname);

  // 检查用户认证状态
  useEffect(() => {
    console.log('探索页面加载，用户认证状态:', isAuthenticated ? '已登录' : '未登录');
    if (isAuthenticated) {
      console.log('已登录用户:', nickname || '未设置昵称');
    }
  }, [isAuthenticated, nickname]);

  const filteredLocations = locations.filter(location => {
    const matchesRegion = !selectedRegion || location.region === selectedRegion;
    const matchesProvince = !selectedProvince || location.province.id === selectedProvince;
    const matchesCategory = !selectedCategory || location.category.id === selectedCategory;
    const matchesRating = location.rating >= minRating;
    return matchesRegion && matchesProvince && matchesCategory && matchesRating;
  });

  const groupedLocations = filteredLocations.reduce((acc, location) => {
    const category = location.category.name;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(location);
    return acc;
  }, {} as Record<string, Location[]>);

  const clearFilters = () => {
    setSelectedRegion('');
    setSelectedProvince('');
    setSelectedCategory('');
    setMinRating(0);
  };

  return (
    <>
      <SEO 
        title="探索县城 - 奔县"
        description="探索中国各地特色县城，发现自然与人文之美"
        keywords="县城探索,地方特色,自然风光,人文景观,旅游攻略"
      />
      <div className="min-h-screen bg-gray-50">
        <header className="bg-white shadow-sm">
          <div className="max-w-5xl mx-auto px-4 py-4">
            <div className="flex items-center">
              <button 
                onClick={() => navigate('/')}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <ArrowLeft className="w-6 h-6 text-gray-600" />
              </button>
              <h1 className="ml-4 text-2xl font-bold text-gray-800">奔县</h1>
            </div>
          </div>
        </header>

        <main className="max-w-5xl mx-auto px-4 py-8">
          <ExploreFilters
            regions={regions}
            provinces={provinces}
            categories={categories}
            selectedRegion={selectedRegion}
            selectedProvince={selectedProvince}
            selectedCategory={selectedCategory}
            minRating={minRating}
            onRegionChange={setSelectedRegion}
            onProvinceChange={setSelectedProvince}
            onCategoryChange={setSelectedCategory}
            onRatingChange={setMinRating}
            onClearFilters={clearFilters}
          />

          {Object.entries(groupedLocations).map(([category, items]) => (
            <section key={category} className="mb-12">
              <h2 className="text-xl font-semibold text-gray-800 mb-6">{category}</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {items.map((location) => (
                  <LocationCard key={location.id} location={location} />
                ))}
              </div>
            </section>
          ))}

          {Object.keys(groupedLocations).length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500">没有找到符合条件的县城</p>
            </div>
          )}
        </main>
      </div>
    </>
  );
}